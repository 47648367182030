
import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ServiceMap from "../components/service-map";

export const query = graphql`
  query ServiceFinderPageQuery {
    page: allSanityPageServiceFinder {
      nodes {
        title
        subtitle
        searchTitle
        searchSubtitle
        searchEmpty
      }
    },
    locations: allSanityServicelocation {
      nodes {
            _id
            id
            title
            surname
            address
            kind
            phone
            kind
            email
            location {
              lat
              lng
            }
      }
    }
  }
`;


const Map = ({ data }) => {
  const page = data.page.nodes[0];

  return (
    <Layout className="service-finder">
      <SEO title={page.title} description={page.subtitle} />
      <Hero title={page.title} description={page.subtitle}></Hero>
      <div className="container">
        <ServiceMap
          locs={data.locations.nodes}
          searchTitle={page.searchTitle}
          searchSubtitle={page.searchSubtitle}
          searchEmpty={page.searchEmpty}
        />
      </div>
    </Layout>
  );
};

export default Map;
